import "./App.css";
import "./styles/main.css";
import { theme } from "./muitheme";
import * as Sentry from "@sentry/react";
import Clarity from "@microsoft/clarity";
//import mixpanel from "mixpanel-browser";
import { ThemeProvider } from "@mui/material";
import { SubDomain } from "./pages/SubDomain";
import { Route, Routes } from "react-router-dom";
import StartConsultation from "./pages/StartConsultation";
import { AppContextProvider } from "./contexts/appContext";
import CreateConsultation from "./pages/CreateConsultation";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const project_id = process.env.REACT_APP_CLARITY_PROJECT_ID;
Clarity.init(project_id);

// mixpanel.init(process.env.REACT_APP_PROJECT_ID, {debug: true});
// update
// Create a client
const queryClient = new QueryClient();

function App() {
  function clearCacheStorage() {
    try {
      caches.keys().then(function (names) {
        for (let name of names) {
          caches.delete(name);
          window.location.reload();
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  clearCacheStorage();

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <AppContextProvider>
          <Routes>
            <Route path="/" element={<StartConsultation />} />
            <Route path="/:subdomain" element={<SubDomain />} />
            <Route
              path="/:subdomain/create-consultation"
              element={<CreateConsultation />}
            />
            <Route
              path="/:subdomain/hospital-consultation"
              element={<CreateConsultation />}
            />
          </Routes>
        </AppContextProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default Sentry.withProfiler(App);

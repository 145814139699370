import React, { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { InputContainer } from "../../InputContainer";
import { Autocomplete, TextField } from "@mui/material";
import { useAppState } from "../../../contexts/appContext";
import { getAutoCompleteStyles } from "../../../utils/funcs";

const deliveryFeatureWhiteList = (
  process.env.REACT_APP_DELIVERY_WHITELIST || ""
).split(" ");

const nemHMO = (process.env.REACT_APP_NEM_HMO || "").split(" ");

const defaultPharmacy = {
  pharmacyName: "--Select Pharmacy--",
  pharmacyCode: null,
  address: null,
  area: null,
  lga: null,
  state: null,
};

export const PharmacyAndDeliverySelection = ({ loading, pharmacyArray }) => {
  const appState = useAppState();
  const { widgetColor, providerId } = appState?.partnerInfo || {};

  const pharmacyArr = useMemo(
    () => [defaultPharmacy, ...pharmacyArray],
    [pharmacyArray]
  );

  const {
    register,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const isNemHMO = nemHMO.includes(providerId);
  const canSelectDeliveryMode = deliveryFeatureWhiteList.includes(providerId);

  const watchPharmacyCode = watch("pharmacyCode");

  useEffect(() => {
    const pharmacyCode = getValues()?.pharmacyCode;
    const noPharmacySelected = !pharmacyCode || pharmacyCode === "";

    const foundPharmacy = (pharmacyArr || []).filter(
      (pharmacy) => pharmacyCode === pharmacy?.pharmacyCode
    )[0];

    const pharmName = noPharmacySelected ? null : foundPharmacy?.pharmacyName;
    setValue("pharmacyName", pharmName);
    const { address, area, lga, state } = foundPharmacy || {};
    const pharmacyAddress = noPharmacySelected
      ? null
      : `${address}, ${area}, ${lga}, ${state}.`;
    setValue("pharmacyAddress", pharmacyAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchPharmacyCode]);

  useEffect(() => {
    if (!canSelectDeliveryMode) {
      setValue("isDelivery", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canSelectDeliveryMode]);

  const handleOptionClick = (pharmacyCode) => {
    setValue("pharmacyCode", pharmacyCode);
  };

  const selectedPharmacyCode = getValues()?.pharmacyCode;

  const selectedOption = useMemo(() => {
    const filteredPharmacyArr = (pharmacyArr || []).filter(
      (pharm) => pharm?.pharmacyCode === selectedPharmacyCode
    );

    return filteredPharmacyArr?.length > 0
      ? filteredPharmacyArr[0]
      : defaultPharmacy;
  }, [pharmacyArr, selectedPharmacyCode]);

  return (
    <div>
      {isNemHMO && (
        <p className="max-w-[450px] bg-yellow-50 !text-yellow-800 border border-yellow-500 rounded-md !p-2 !mt-5">
          <b>PS:</b> Your chosen pharmacy cannot be changed. <br /> However,
          orders can only be reroute based on medication unavailability at your
          preferred pharmacy.
        </p>
      )}
      <InputContainer
        label="Select Your Preferred Pickup Pharmacy"
        info="Selecting a pharmacy closest to you will help us process your drugs faster."
        error={errors["pharmacyCode"]?.message}
        required
      >
        {/* NEW CUSTOM AUTOCOMPLETE */}
        <Autocomplete
          id="pharmacy-autocomplete"
          loading={loading}
          clearOnBlur={true}
          disableClearable={true}
          options={pharmacyArray}
          sx={getAutoCompleteStyles(widgetColor, false)}
          value={
            selectedOption
              ? (pharmacyArray || []).find((option) => {
                  return selectedOption?.pharmacyCode === option?.pharmacyCode;
                }) ?? null
              : null
          }
          isOptionEqualToValue={(option, value) => {
            return option?.pharmacyCode === value?.pharmacyCode;
          }}
          getOptionLabel={(option) => {
            return option?.pharmacyName || "";
          }}
          onChange={(_, newValue) => {
            if (newValue) {
              handleOptionClick(newValue?.pharmacyCode);
              const key = newValue?.symbol;
              setValue("currency", key);
            }
          }}
          renderOption={(props, option) => {
            const pharmName = option?.pharmacyName
              ? option?.pharmacyName
              : "No name";
            const pharmAddress = option?.address ? `${option?.address}, ` : "";

            const pharmArea = option?.area ? `${option?.area}, ` : "";
            const pharmLGA = option?.lga ? `${option?.lga}, ` : "";
            const pharmState = option?.state ? `${option?.state} state.` : "";
            const pharmCode = option?.pharmacyCode;
            const isSelected = pharmCode === getValues()?.pharmacyCode;
            const { key, ...optionProps } = props;

            return (
              <div
                key={pharmCode}
                {...optionProps}
                className="!px-3 !py-2 border-b cursor-pointer hover:bg-gray-50"
                style={
                  isSelected
                    ? {
                        backgroundColor: widgetColor,
                      }
                    : {}
                }
              >
                <p
                  style={
                    isSelected ? { color: "#fff" } : { color: widgetColor }
                  }
                  className="text-sm font-medium"
                >
                  {pharmName}
                </p>
                <p
                  style={isSelected ? { color: "#fff" } : { color: "#0c1322" }}
                  className="text-xs text-gr"
                >
                  {`${pharmAddress}${pharmArea}${pharmLGA}${pharmState}`}
                </p>
              </div>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{ shrink: false }}
              placeholder="Type to search by name"
            />
          )}
        />
      </InputContainer>
      {canSelectDeliveryMode && (
        <InputContainer
          label="Select fulfillment option"
          error={errors["isDelivery"]?.message}
        >
          <div className="space-y-1 pt-5">
            <label className="flex items-center space-x-2" htmlFor="pickup">
              <input
                type="radio"
                style={{
                  color: widgetColor,
                  accentColor: widgetColor,
                }}
                className="w-4 h-4"
                id="pickup"
                name="isDelivery"
                value={false}
                {...register("isDelivery")}
              />
              <span>Pharmacy Pickup</span>
            </label>
            <label className="flex items-center space-x-2" htmlFor="delivery">
              <input
                type="radio"
                style={{
                  color: widgetColor,
                  accentColor: widgetColor,
                }}
                className="w-4 h-4"
                id="delivery"
                name="isDelivery"
                value={true}
                {...register("isDelivery")}
              />
              <span>Home Delivery</span>
            </label>
          </div>
        </InputContainer>
      )}
    </div>
  );
};
